import React from "react";

import "~/src/styles/global.css";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Fragment>
      <div className="min-h-screen min-w-full">{children}</div>
    </React.Fragment>
  );
};

export default Layout;
