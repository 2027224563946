import * as React from "react";

import Layout from "~/src/components/Layout";
import SEO from "~/src/components/SEO";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>¿Qué buscas? ^.-</p>
  </Layout>
);

export default NotFoundPage;
